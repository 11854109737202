import React from 'react';
import { makeStyles, Menu, withStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  name: {
    paddingTop: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    opacity: 1,
    letterSpacing: '0.08px',
  },
  editProfile: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    opacity: 1,
    letterSpacing: '0.07px',
    color: '#1E88E5',
    fontWeight: 500,
    cursor: 'pointer',
  },
  Logout: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    opacity: 1,
    letterSpacing: '0.07px',
    fontWeight: 500,
  },
  popover: {
    pointerEvents: 'none',
  },
  avatarMini: {
    backgroundColor: 'rgb(196,186,130)',
    textAlign: 'center',
    margin: 'auto',
    width: '28px',
    height: '28px',
    border: '2px solid #E3E3E3',
    fontSize: '15px',
    fontWeight: 400,
    textTransform: 'uppercase',
    outline: '0',
    color: '#FFFFFF',
  },
  avatar: {
    backgroundColor: 'rgb(196,186,130)',
    textAlign: 'center',
    margin: 'auto',
    width: '50px',
    height: '50px',
    border: '2px solid #E3E3E3',
    textTransform: 'uppercase',
    outline: '0',
    color: '#fff',
  },
  avatarImg: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  focus: {
    outline: '0',
  },
}));

export const StyledMenu = withStyles({
  paper: {
    top: '48px !important',
    height: 'auto',
    width: '184px',
    boxShadow: '0px 3px 6px #00000029',
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
