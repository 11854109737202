import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import {
  Typography,
  Divider,
  MenuItem,
  ListItemIcon,
  Grid,
  Link,
  IconButton,
  Avatar,
} from "@material-ui/core";
import { removeUserSession } from "../../services/AuthenticationService";
import { locale } from "../../services/locale";
import { useStyles, StyledMenu } from './AccountStyles';

const Logout = require("../../assets/images/logout.svg");
const WhiteLogout = require("../../assets/images/logout_white.svg");

export default function Account({ name, avatar }: any) {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const companyCode = localStorage.getItem("companyCode");

  const handleAvatarLetter = (name: any) => {
    const split = name.toLowerCase().split(' ');
    if (split.length > 1) return split[0].charAt(0) + split[split.length - 1].charAt(0);
    return split[0].charAt(0);
  }

  const handleClickLogout = () => {
    removeUserSession();
    localStorage.removeItem("token");
    localStorage.removeItem("companyCode");
    localStorage.removeItem("CompanyCode-Login");
    window.location.href = 'http://localhost:3001';
  };

  const handleClickSingOutCompany = () => {
    localStorage.removeItem("token");
    history.push(`/${companyCode}`);
  };

  const handleEditProfile = () => {
    history.push(`/${companyCode}/admin/userprofile`);
  }

  const darkMode = localStorage.getItem("darkMode");
  return (
    <SnackbarProvider autoHideDuration={3000} maxSnack={2}>
      <Avatar
        aria-label="recipe"
        className={classes.avatarMini}
        onClick={(e: any) => setAnchorEl(e.currentTarget)}
      >
        {avatar
          ? <img src={avatar} alt={name} className={classes.avatarImg} />
          : handleAvatarLetter(name)
        }
      </Avatar>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Grid container>
          <Grid item xs={5} style={{ textAlign: "center" }}>
            <IconButton style={{ padding: "5px" }}>
              <Avatar aria-label="recipe" className={classes.avatar}>
                {avatar
                  ? <img src={avatar} alt={name} className={classes.avatarImg} />
                  : handleAvatarLetter(name)
                }
              </Avatar>
            </IconButton>
          </Grid>
          <Grid item xs={7}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.name}>{name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Link className={classes.editProfile} onClick={handleEditProfile}>
                  {locale('Edit Profile')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* </MenuItem> */}
        <Divider />
        <MenuItem onClick={handleClickSingOutCompany}>
          <ListItemIcon style={{ minWidth: "33px" }}>
            <img src={darkMode == "true" ? WhiteLogout : Logout} alt="Logout" />
          </ListItemIcon>
          <Typography className={classes.Logout}>
            {locale('Logout')}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem style={{ paddingTop: 10, paddingBottom: 12 }} onClick={handleClickLogout}>
          <ListItemIcon style={{ minWidth: "33px" }}>
            <img src={darkMode == "true" ? WhiteLogout : Logout} alt="Logout" />
          </ListItemIcon>
          <Typography className={classes.Logout} >
            {locale('Sign Out Company')}
          </Typography>
        </MenuItem>
      </StyledMenu>
    </SnackbarProvider>
  );
}
