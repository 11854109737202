import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from "@material-ui/core";
import {
  Typography,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Switch,
  Grid,
  Button,
} from "@material-ui/core";
import ajax from '../services/ajax';
import { BASE_API_URL, PRODUCT_CODE } from '../configurations/constant';
import { getFactoryId } from '../services/FactoryService';
import { getCompanyCode } from '../services/AuthenticationService';
import { Context as LocaleContext } from '../services/locale';

const Brightness4IconBlue = require("../assets/images/settings_darkmode_blue.svg");
const Brightness4IconWhite = require("../assets/images/settings_darkmode_white.svg");
const Globe = require("../assets/images/settings_language_blue.svg");
const GlobeWhite = require("../assets/images/settings_language_white.svg");
const Settings = require("../assets/images/settings_white.svg");

const StyledMenu = withStyles({
  paper: {
    top: "48px !important",
    height: "auto",
    width: "184px",
    boxShadow: "0px 3px 6px #00000029",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  Language: {
    fontFamily: "Roboto",
    fontSize: "14px",
    opacity: 1,
    letterSpacing: "0.07px",
    fontWeight: 500
  },
  Off: {
    color: "#868686",
    fontFamily: "Roboto",
    fontSize: "12px",
    opacity: 1,
    letterSpacing: "0.07px",
    fontWeight: 500
  },
  Link: {
    color: "#1E88E5",
    fontFamily: "Roboto",
    fontSize: "12px",
    opacity: 1,
    letterSpacing: "0.07px",
    fontWeight: 500
  }
}));

export default function LanguageSwitcher({ darkMode, setDarkMode }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [languages, setLanguages] = useState([]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const selectLangauge = (lang, setLanguage)=>{
    setLanguage(lang);
    handleClose();
  }

  useEffect(()=>{
    ajax(`${BASE_API_URL}languages`, {
      params: {
        companyCode: getCompanyCode(),
        app: PRODUCT_CODE,
        factoryId: getFactoryId(),
      }
    }).then(({ data })=>{
      setLanguages(data);
    })
  }, [])

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="" aria-label="settings">
        <Button
          aria-label="settings"
          style={{ padding: "10px 8px 4px 8px" }}
        >
          <img
            src={Settings}
            alt="Settings"
            onClick={handleOpen}
          />
        </Button>
      </Tooltip>
      <LocaleContext.Consumer>
        {({ setLanguage })=>( 
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={isOpen}
            onClose={handleClose}
          >
            <MenuItem dense={true} style={{ marginTop: "1vh" }}>
              <ListItemIcon style={{ minWidth: "33px" }}>
                <img
                  src={darkMode ? GlobeWhite : Globe}
                  alt="globe"
                />
              </ListItemIcon>
              <Typography className={classes.Language}>
                Language
              </Typography>
            </MenuItem>
            {
              languages.map(({ id, desc2, language })=>(
                <MenuItem onClick={()=> selectLangauge(language, setLanguage)} key={id} dense={true}>
                  <ListItemIcon
                    style={{ minWidth: "33px" }}
                  ></ListItemIcon>
                  <Typography className={classes.Link}>
                    {desc2}
                  </Typography>
                </MenuItem>
              ))
            }
            <Divider />
            <MenuItem dense={true} style={{ marginTop: "1.2vh", marginBottom: "1.2vh" }}>
              <ListItemIcon style={{ minWidth: "33px", marginTop: "-17px" }}>
                {!darkMode ? (
                  <img
                    src={Brightness4IconBlue}
                    alt="Brightness4Icon"
                  />
                ) : (
                  <img
                    src={Brightness4IconWhite}
                    alt="Brightness4Icon"
                  />
                )}
              </ListItemIcon>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.Language}>
                    Dark Mode
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.Off}>
                    {!darkMode ? "Off" : "On"}
                  </Typography>
                </Grid>
              </Grid>
              <Switch
                value="Off"
                inputProps={{ role: "switch" }}
                onClick={() => {
                  setDarkMode(!darkMode);
                }}
              />
            </MenuItem>
          </StyledMenu>
        )}
      </LocaleContext.Consumer>
    </>

  )

}