import React, { Suspense } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  isLogIn: boolean;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, isLogIn, ...rest } = props;

  return (
    <Route
        {...rest}
        render={(routeProps) => (
            <Suspense fallback={''}>
                {localStorage.getItem("token") ? (
                    <Component {...routeProps} />
                ) : (
                    (localStorage.getItem("companyCode") || localStorage.getItem("CompanyCode-Login")) ? 
                      <Redirect to={`/${localStorage.getItem('companyCode')}`} /> 
                    :
                      <Redirect to="/" /> 
                )}
            </Suspense>
        )}
    />
  );
};

export default PrivateRoute;
