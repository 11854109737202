import {makeStyles} from "@material-ui/core";

const drawerWidth = 48;
const drawerRight = "auto";
// const drawerRight = 80;
const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
    avatar: {
      background:
      "transparent 0% 0% no-repeat padding-box",
      height: "30px",
      width: "30px",
      borderRadius: "20px",
      border: "2px solid #E3E3E3",
      opacity: "1",
      marginTop: "6px",
      objectFit: "cover",
    },
    appBar: {
      boxShadow: "0 0 black",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
      marginLeft: drawerWidth,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      height: "100%",
    },
    drawerHeader: {
      marginTop: 48,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "@media(min-width:1024px)": {
        minHeight: "36px",
      },
      "@media(min-width:600px)": {
        minHeight: "36px",
      },
      "@media (min-width: 0px)": {
        minHeight: "36px",
      },
      justifyContent: "flex-end",
    },
    drawerOpen: {
      color: "#FFFFFF",
      width: drawerWidth,
      fontSize: "0.750rem",
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      color: "#FFFFFF",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: "100%",
      overflowX: "hidden",
      width: "48px",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      width: drawerRight,
      overflow: "inherit",
      backgroundColor: "#FFFFFF",
      borderLeft: "none"
    },
    content: {
      flexGrow: 1,
      // marginRight: -drawerWidth,
      // overflowY: "hidden",
      overflowX: "hidden",
      height:"100vh"
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
      marginLeft: 0,
      overflowX: "hidden",
    },
    CompanyLogo: {
      marginLeft: theme.spacing(2),
      fontSize: "0.875rem",
      fontWeight: "bold",
    },
    grow: {
      flexGrow: 1,
    },
    img: {
      textAlign: "center",
      margin: "auto",
      display: "block",
      maxWidth: "50px",
      maxHeight: "50px",
    },
    // name: {
    //   paddingTop: "10px",
    //   fontFamily: "Roboto",
    //   color: "#515151",
    //   fontSize: "14px",
    //   fontWeight: "bold",
    //   opacity: 1,
    //   letterSpacing: "0.08px",
    // },
    // editProfile: {
    //   color: "#1E88E5",
    //   fontFamily: "Roboto",
    //   fontSize: "12px",
    //   opacity: 1,
    //   letterSpacing: "0.07px",
    // },
  }));
  export default useStyles