import React from "react";
import {
    Box,
    IconButton,
    createStyles,
    makeStyles,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import { IFRAME_ZOHO_SRC } from "../configurations/constant";

const FeedBackButton = () => {
    const classes = useStyle();
    const [show, setShow] = React.useState(false);
    const [screenSize, setScreenSize] = React.useState({ width: 0, height: 0 });

    const getCurrentDimension = () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    };

    const handleMessage = (e: any) => {
        if (e.data === 'reloadZsIframe') {
            window.location.reload();
        }
    };

    React.useEffect(() => {
        setScreenSize(getCurrentDimension());
        window.addEventListener('message', (e: any) => {
            if (e.data === 'reloadZsIframe') {
                window.location.reload();
            }
        });
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    let iframeWidth = screenSize.width > 720
        ? "580"
        : screenSize.width - screenSize.width / 10;
    let iframeHeight = screenSize.height > 600
        ? "510"
        : screenSize.height - screenSize.height / 10;

    return (
        <Box className={classes.container}>
            <IconButton onClick={() => setShow(true)} color="default">
                <HelpOutlineIcon style={{ color: '#fff', fontSize: 34 }} />
            </IconButton>
            <Box style={{ display: show ? "" : "none" }} className={classes.iFrameContainer}>
                <IconButton className={classes.closeButton} onClick={() => setShow(false)} color="default">
                    <HighlightOffIcon />
                </IconButton>
                {/* <iframe width={iframeWidth} height={iframeHeight} scrolling="no" src={IFRAME_ZOHO_SRC}></iframe> */}
            </Box>
        </Box>
    );
};

const useStyle = makeStyles(() =>
    createStyles({
        container: {
            position: 'relative',
            top: 0,
            left: 0,
        },
        closeButton: {
            position: "absolute",
            width: 50,
            height: 50,
            zIndex: 999,
            top: "50%",
            left: "50%",
            transform: "translate(235px, -245px)"
        },
        iFrameContainer: {
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
            width: "100vw",
            zIndex: 998,
            "& iframe": {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                border: 0,
            },
            "&::before": {
                content: '" "',
                position: "absolute",
                inset: 0,
                bottom: 0,
                width: "100vw",
                height: "100vh",
                background: "rgba(0, 0, 0, 0.5)"
            }
        }
    })
);

export default FeedBackButton;
