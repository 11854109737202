/**
 * This is context for localization
 */
import {
  createContext,
  useEffect,
  useState,
  createElement as re,
} from 'react';
import ajax from '../ajax';
import {
  read as readLocale,
  write as writeLocale,
  setLanguage as setLang,
  getLanguage,
  cleanLocale,
} from './locale.js';
import { getCompanyCode } from '../AuthenticationService';
import { BASE_API_URL } from "../../configurations/constant";

/**
 * Makes request to get the updated localiaztion data,
 * and update if the new version available
 */
function requestLocale({ language, setLocale, setLanguage }) {
  const companyCode = getCompanyCode();
  const params = {
    product: 'admin', // Temporary use LTm and wait for global admin json present in server // PRODUCT_CODE.toLowerCase(),
    compact: 1,
    version: 'new',
    companyCode,
    language,
  };

  const url = `${BASE_API_URL}languages/translation`;

  return ajax(url, { params }).then(({ data }) => {
    const locale = cleanLocale(data || {});
    writeLocale(locale);
    setLocale(readLocale());
  });
}

export const Context = createContext({});

/**
 * The context provider
 *
 * @todo Will use useResponse instread of useEffect
 * @param {array} children The component children
 */
export function ContextProvider({ children }) {
  const [locale, setLocale] = useState(readLocale());
  const [language, setLanguage] = useState(getLanguage());

  useEffect(() => {
    setLang(language);
    requestLocale({ setLocale, language });
  }, [language]);

  // @todo clean this after it works properly
  // const value = { locale, language, setLanguage };
  const value = { language, setLanguage, locale };
  return re(Context.Provider, { value }, children);
}

/**
 * Escapes by replacing the %{index} according to array items
 * listed in the `params`.
 * @param {string} phrase The localized phrase
 * @param {array} params The value to be binded to localized phrase
 * @return string
 */
function escapePhrase(phrase, params) {
  if (params.length === 0) return phrase;
  return params.reduce((carry, value, index) => {
    const tmp = carry.replaceAll(`%{${index}}`, value);
    return tmp;
  }, phrase);
}

/**
 * The Locale companent
 * @example <Locale>Welcome Home</Locale>
 * @todo Will remove param and us object binded value %{1} to ${user}
 *
 * @param {any} children The component children
 * @param {array} params [Optional] The value to be binded to localized phrase
 * @return ReactJS Component
 */
export function Locale({ children, params = [] }) {
  const key = children.toString();
  return locale(children, params);
  /*
  @todo Clean this when everything works fine
  return re(Context.Consumer, {}, ({ locale }) => {
    const phrase = locale[key.toLowerCase()] || children;
    return escapePhrase(phrase, params);
  });
  */
}

/**
 * The locale function
 * @param {string} phrase Phrase to be translated
 * @param {array} params Value list to be binded to translated phrase.
 * @return string
 */
export function locale(phrase, params = []) {
  const locale = readLocale();
  const translated = locale[phrase.toLowerCase()] || phrase;
  return escapePhrase(translated, params);
}
