import thunk from 'redux-thunk';
import reducers from './Reducers';
import { 
    compose, 
    createStore, 
    applyMiddleware,
} from 'redux';
import { withRouter } from 'react-router-dom';
import { routerMiddleware } from 'react-router-redux';

const enhancers = [];
const middleware = routerMiddleware(withRouter);
let composedEnhancers  = compose(
    applyMiddleware(thunk, middleware),
    ...enhancers
);
const store = createStore(
    reducers,
    composedEnhancers,
);
export default store;