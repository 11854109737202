const KEY = 'token';
const { localStorage } = window;

/**
 * Set the userToken into localStorage
 * @param {string} token: User Token
 */
export const setToken = (token: string) => {
  localStorage.setItem(KEY, token);
}

/**
 * Gets a userToken stored in the localStorage,
 * @return false|string
 */
export const getToken = () => {
  return localStorage.getItem("token_1");
}
