import React from 'react'
import { Paper, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { locale } from "../../services/locale";

const AllLine = require("../../assets/images/UserManagement_color.svg")

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: "center",
        width: 224,
        // height: 167,
        margin: "auto",
        paddingTop: 25,
        borderRadius: "12px",
        backgroundColor: "#F7F7FB",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "0px solid #7070701A",
        opacity: 1,
        boxShadow: "none",
    },
    title: {
        textAlign: "center",
        fontFamily: "Roboto",
        fontSize: "16px",
        color: "#000000",
        fontWeight: "bold"
    },
    subtitle: {
        textAlign: "center",
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#515151",
    }
}));

function AllLines() {
    const classes = useStyles();
    return (
        <div style={{ textAlign: "center" }}>
            {/* <Paper className={classes.paper}>
                <img src={AllLine} alt="User Management" />
                <Typography className={classes.title}>User Management</Typography>
                <Typography className={classes.subtitle}>Manager users, access rights <br /> and role permissions</Typography>
            </Paper> */}
            <h3>{locale('ALL Llines')}</h3>
        </div>
    )
}

export default AllLines
