import { Divider, Theme, Tooltip, Typography, makeStyles, withStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    imageIcon: {
        padding: "px",
        textAlign: "center",
        width: "28px",
        height: "28px",
        marginBottom: "20px",
        fontSize: "9px",
    },
    imageIcons: {
        padding: "3px",
        textAlign: "center",
        background: "#fff",
        width: "28px",
        height: "28px",
        marginBottom: "20px",
        fontSize: "9px",
    },
    canter: {
        textAlign: "center",
    },
    customTooltip: {
        backgroundColor: "#515151",
        color: "#ffffff",
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        fontFamily: "Roboto",
        fontSize: "16px",
        left: "18%",
    },
    disabledMenuItem: {
        pointerEvents: "none",
        opacity: 0.6,
    },
    form: {
        display: "flex",
        alignItems: "center",
        width: "auto",
        borderRadius: 0,
        cursor: "pointer",
        marginRight: "15px",
    }
}));

export const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.secondary.main,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        height: 48,
        width: 216,
        borderRadius: 0,
        justifyContent: "center",
        textAlign: "center",
    },
}))(Tooltip);

export const CustomDivider = withStyles((theme) => ({
    root: {
        alignSelf: "stretch",
        marginLeft: "none",
        marginRight: "5px",
    },
}))(Divider);

export const Title = withStyles((theme) => ({
    root: {
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 300,
    },
}))(Typography);