import { isSuperAdmin } from "../pages/UserManagement/Action/helper";

export const getCroppedImg = async (
    imageSrc, 
    pixelCrop, 
    rotation = 0,
  ) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  )

  // As a blob
  return new Promise(resolve => 
    canvas.toBlob(file => { resolve(file) }, 'image/jpeg') 
  )
}

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  })
}

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  })

const getRadianAngle = (degreeValue) => (degreeValue * Math.PI) / 180;

export const getParams = (param) => {
  try {
    const urlParams = new URLSearchParams(window.location.search); 
    return urlParams.get(param);
  } catch (error) {
    console.log(error);
    return '';
  }
}

export const checkingPageAccess = (dataAccess, page, isSuperAdmin) => {
  if(isSuperAdmin === 'true') return true;
  const foundAccess = dataAccess.find((item) => 
    item.page === page && item.pageAccess !== "NONE"
  );
  return foundAccess ? true : false;
}

export const checkingActionAccess = (page) => {
  if(isSuperAdmin()) return true;
  const userAccessString = localStorage.getItem('userAccess');
  if(userAccessString) {
    const dataAccess = JSON.parse(userAccessString);
    const foundAccess = dataAccess.find((item) => 
      item.page === page && 
      item.pageAccess !== "NONE" &&
      item.pageAccess !== "VIEW"
    );
    return foundAccess ? true : false;
  }
  return false;
}

export const getErrorResponseMessage = (response) => {
  if(response.data.data.error){
    return response.data.data.error;
  }
  else if (response.data.data) {
    return response.data.data;
  }
  else {
    return 'Somthing went wrong! Please try again.';
  }
}