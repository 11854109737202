import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { BASE_API_URL } from "../../configurations/constant";
import ajax from "../../services/ajax";
import { productModules } from "../../configurations/productModules";
import LeftMenuItem from "./LeftMenuItem";
import { checkingPageAccess } from "../../helpers";

const LeftMenu = () => {
  const [menus, setMenus] = useState<any[]>(productModules);

  useEffect(() => {
    getListAccess();
  }, []);

  const getListAccess = async () => {
    try {
      const { data } = await ajax(`${BASE_API_URL}useraccess?product=Admin`);
      localStorage.setItem('userAccess', JSON.stringify(data.access));
      setMenus(menus?.map((menu: any) => ({
        ...menu,
        enableAccess: checkingPageAccess(
          data.access,
          menu.page,
          data.isSuperAdmin
        ),
      })));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SnackbarProvider autoHideDuration={3000} maxSnack={2}>
      <div>
        {menus.map((item) => (<LeftMenuItem item={item} />))}
      </div>
    </SnackbarProvider>
  );
};

export default LeftMenu;
