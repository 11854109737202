import * as React from 'react';
import { Grid, List, Tooltip } from '@material-ui/core';
import { locale } from '../../services/locale';
import {
    CustomDivider,
    LightTooltip,
    Title,
    useStyles,
} from './styles';
import { imagesMenu } from '../../configurations/productModules';
import { Link } from 'react-router-dom';
import { getCompanyCode } from '../../services/AuthenticationService';
const DefaultImage = require("../../assets/images/default_image_ltlabs_logo.svg");

const LeftMenuItem = ({ item }: any) => {
    const classes = useStyles();
    const menu = imagesMenu.find(({ page }) => page === item.page);
    const navigation = (path: any) => `/${getCompanyCode()}/admin/${path}`;

    if (item.enableAccess) {
        return (
            <Link to={navigation(item.path)}>
                <List style={{ textAlign: 'center' }}>
                    <LightTooltip
                        title={
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <form className={classes.form}>
                                        <CustomDivider />
                                        <CustomDivider orientation="vertical" />
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <img src={menu?.icon || DefaultImage} alt={item.title} style={{ width: "25px", height: "25px", textAlign: "center" }} />
                                            </Grid>
                                            <Grid item xs={10}> <Title>{locale(item.title)}</Title></Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </React.Fragment>
                        }
                        placement="right"
                    >
                        <img src={menu?.icon || DefaultImage} alt="User Management" className={classes.imageIcon} />
                    </LightTooltip>
                </List>
            </Link>
        )
    }

    return (
        <List style={{ textAlign: "center" }}>
            <Tooltip
                title={locale(
                    "Your account doesn't have access right to work on this module. If you have any questions, please contact your account manager."
                )}
                placement="right"
                arrow
                classes={{ tooltip: classes.customTooltip }}
            >
                <div><img src={menu?.icon || DefaultImage} alt={item.title} className={`${classes.imageIcon} ${classes.disabledMenuItem}`} /></div>
            </Tooltip>
        </List>
    )
}

export default LeftMenuItem;