/**
 * Ajax module using axio
 */
 import axios from 'axios';
 import { getToken } from '../UserTokenService';
 
 /**
  * Gets the header properties to be added into axios
  * @param {object} headers Header information object
  * @return object
  */
 function getHeaders({ headers = {} }) {
   const userToken = getToken();
   return {
     ...headers,
     Authorization: `Bearer ${userToken}`,
     'X-Requested-With': 'XMLHttpRequest',
   };
 }
 
 /**
  * Gets the defualt properties to be passed
  * to the axios properties
  * @param {object} props Properties passed to ajax
  * @return object
  */
 function getProps(props = {}) {
   return {
     method: 'GET',
     responseType: 'json',
     ...props,
   };
 }
 
 /*
  * @param {string} url URL to be requested to
  * @param {object} props Properties of Axios
  * @return promise
  */
 export default function ajax(url, pProps = {}) {
   const props = getProps({
     ...pProps,
     url,
     headers: getHeaders(pProps),
   });
 
   return axios(props).then(({ data }) => data);
 }
 
 