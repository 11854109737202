import { BASE_API_URL } from '../configurations/constant';
import ajax from './ajax';

export const getFactoryId = () => localStorage.getItem('factoryId');

export const getUserFactories = (callback) => {
  let url = `${BASE_API_URL}user/factories`;
  ajax(url).then(({ data }) => 
    callback(data)
  );
}

export const getCustomerFactories = (customerId, callback) => {
  let url = `${BASE_API_URL}customers/factories/${customerId}`;
  ajax(url).then(({ customerFactories }) => callback(customerFactories));
}

export const getSeasonFactories = (seasonId, callback) => {
  let url = `${BASE_API_URL}seasons/factories/${seasonId}`;
  ajax(url).then(({ seasonFactories }) => callback(seasonFactories));
}