import {
    LOADING,
    SUCCESS,
    ERROR,
} from './../ActionTypes/profileActionTypes';

const profileReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case LOADING: return {
            ...state,
            isLoading: true,
        }
        case SUCCESS: return {
            ...state,
            name: payload.name,
            avatar: payload.avatar,
            companyName: payload.desc1,
            companyCode: payload.company,
            companyLogo: payload.profileImgLink,
            isLoading: false,
        }
        case ERROR: return {
            ...state,
            isLoading: false,
            error: payload,
        }
        default: return state;
    }
};

const initialState = {
    name: '',
    avatar: '',
    companyName: '',
    companyCode: '',
    companyLogo: '',
    isLoading: false,
    error: false,
};

export default profileReducer;