import React, {Suspense, useEffect, useState} from "react";
import {SnackbarProvider} from "notistack";
import {useLocation} from "react-router-dom";
import clsx from "clsx";
import {
  AppBar,
  Button,
  createTheme,
  CssBaseline,
  Drawer,
  IconButton,
  ListItem,
  responsiveFontSizes,
  ThemeProvider,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";

import Account from "./Account";
import HeaderStyles from "./HeaderStyles";
import RightMenu from "./RightMenu";
import LeftMenu from "./LeftMenu";
import AppRoutes from "../../AppRoutes";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import {useDispatch, useSelector} from 'react-redux';
import {getData} from "../../Redux/Actions/profileAction";
import FeedBackButton from "../../components/FeedBackButton";

const DefaultImage = require("../../assets/images/default_image_ltlabs_logo.svg");
const Profile = require("../../assets/images/LTadmin.svg");
const RightSideMenu = require("../../assets/images/appdrawer_white.svg");

function Page() {
  const classes = HeaderStyles();
  const theme = useTheme();
  let location = useLocation();
  let localHost = window.location.href;

  let macAdd = localHost.split("?");
  let comCode = macAdd[0].split("/");
  localStorage.setItem("companyCode", comCode[3]);

  let comCodess = localStorage.getItem("companyCode");
  let reloadPage =
    window.location.origin + "/" + comCodess + "/admin/landingpage";

  const handleLanding = () => {
    window.location.href = window.location.origin + "/" + comCodess + "/admin/landingpage";
  };
  
  const [open, setOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const {
    name,
    avatar,
    companyName, 
    companyLogo,
  } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    token && dispatch(getData());
  }, [token]);


  let lightTheme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#000000",
        light: "#515151",
        dark: "#F7F7FB",
      },
      secondary: {
        main: "#325F80", //left menu
        light: "#8FBFE2", //left menu
      },
      text: {
        primary: "#000000",
        secondary: "#515151",
        hint: "#515151",
      },
      background: {
        default: "#F7F7FB", //body background color
        paper: "#FFFFFF",
      },
      common: {
        white: "#FFFFFF",
        black: "#595959",
      },
    },
    typography: {
      fontFamily: ["Roboto"].join(","),
    },
    overrides: {
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: " rgba(0, 0, 0, 0.23)"
        },
        root: {
          "&:hover": {
            borderColor: " rgba(0, 0, 0, 0.23)"
          },
        }
      },
      MuiTableContainer: {
        width: "100%",
        overflowX: "auto"
      },
      MuiTablePagination: {
        toolbar: {
          backgroundColor: "#F7F8FA",
          color: "#515151",
          minHeight: 0,
          marginTop: "12px"
        }
      },
      MuiToolbar: {
        gutters: {
          [theme.breakpoints.up("xs")]: {
            paddingLeft: "9px",
            paddingRight: "0px",
          },
          [theme.breakpoints.up("sm")]: {
            paddingLeft: "9px",
            paddingRight: "0px",
          },
          [theme.breakpoints.up("md")]: {
            paddingLeft: "9px",
            paddingRight: "0px",
          },
          [theme.breakpoints.up("lg")]: {
            paddingLeft: "9px",
            paddingRight: "0px",
          },
        },
        regular: {
          backgroundColor: "#325F80",
          color: "#FFFFFF",
          height: "48px",
          "@media(min-width:1024px)": {
            minHeight: "48px",
          },
          "@media(min-width:600px)": {
            minHeight: "48px",
          },
          "@media (min-width: 0px)": {
            minHeight: "48px",
          },
        },
      },
      MuiListItem: {
        gutters: {
          [theme.breakpoints.up("xs")]: {
            paddingLeft: "12px",
            paddingRight: "16px",
          },
          [theme.breakpoints.up("sm")]: {
            paddingLeft: "12px",
            paddingRight: "16px",
          },
          [theme.breakpoints.up("md")]: {
            paddingLeft: "12px",
            paddingRight: "16px",
          },
          [theme.breakpoints.up("lg")]: {
            paddingLeft: "12px",
            paddingRight: "16px",
          },
        },
      },
      MuiList: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      // DRAWER BACKGROUND COLOR
      MuiDrawer: {
        paper: {
          backgroundColor: "#1E3C50",
        },
      },
      MuiPopover: {
        paper: {
          backgroundColor: "#FFFFFF",
        },
      },
      MuiTypography: {
        root: {
          color: "#515151",
        },
        body1: {
          fontSize: "10px"
        }
      },
      MuiFormControlLabel: {
        root: {
          marginRight: "0px",
        },
        labelPlacementBottom: {
          marginLeft: "0px",
        },
        label: {
          color: "#707070",
          marginLeft: "-5px"
        },
      },
      MuiTooltip: {
        popper: {
          left: "-53px !important",
        },
        tooltip: {
          padding: "0px 0px 0px 0px",
        },
      },
      MuiDivider: {
        vertical: {
          height: "37px",
        },
        root: {
          margin: "revert",
        },
      },
      MuiTab: {
        root: {
          "@media(min-width:1024px)": {
            minWidth: "auto",
          },
          "@media(min-width:600px)": {
            minWidth: "auto",
          },
          "@media (min-width: 0px)": {
            minWidth: "auto",
          },
          "&$selected": {
            color: "#1689FA !important",
            borderBottom: "4px solid #1689FA !important"
          },
        },
      },
      MuiTabs: {
        indicator: {
          borderBottom: "4px solid #1689FA",
        },
      },
      MuiButton: {
        root: {
          borderBottom: "3px solid #325F80",
          borderRadius: 0,
          "&:hover": {
            borderBottom: "3px solid #8FBFE2",
            borderRadius: 0,
          },
          "&:focus": {
            borderBottom: "3px solid #8FBFE2",
            borderRadius: 0,
          },
          "&:active": {
            borderBottom: "3px solid #8FBFE2",
            borderRadius: 0,
          },
        },
      },
      MuiSwitch: {
        root: {
          marginTop: "-19px",
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: "#1565C0 !important"
          },
        },
      },
      MuiRadio: {
        colorSecondary: {
          '&$checked': {
            color: "#1565C0"
          },
        },
      },
      MuiInput: {
        underline: {
          "&:after": {
            borderBottom: "2px solid #3f51b5"
          }
        }
      }
    },
    LeftMenu: {
      backgroundColor: "#325F80",
    },
    DivderLeft: {
      background: "#8FBFE2",
    },
  });

  lightTheme = responsiveFontSizes(lightTheme);

  let darkTheme = createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#FFFFFF",
        light: "#FFFFFF",
        dark: "#141B2D", //company profile bgcolor
      },
      secondary: {
        main: "#3A455E", //left menu
        light: "#9DA7BF", //left menu
      },
      text: {
        primary: "#3A455E",
        secondary: "#CCCCCC",
      },
      background: {
        default: "#2A344A", //body background color
        paper: "#2A344A",
      },
      common: {
        white: "#3A455E",
        black: "#CCCCCC",
      },
    },
    typography: {
      fontFamily: ["Roboto"].join(","),
    },
    overrides: {
      MuiToolbar: {
        gutters: {
          [theme.breakpoints.up("xs")]: {
            paddingLeft: "9px",
            paddingRight: "0px",
          },
          [theme.breakpoints.up("sm")]: {
            paddingLeft: "9px",
            paddingRight: "0px",
          },
          [theme.breakpoints.up("md")]: {
            paddingLeft: "9px",
            paddingRight: "0px",
          },
          [theme.breakpoints.up("lg")]: {
            paddingLeft: "9px",
            paddingRight: "0px",
          },
        },
        regular: {
          backgroundColor: "#1F2940",
          color: "#FFFFFF",
          height: "48px",
          "@media(min-width:1024px)": {
            minHeight: "48px",
          },
          "@media(min-width:600px)": {
            minHeight: "48px",
          },
          "@media (min-width: 0px)": {
            minHeight: "48px",
          },
        },
      },
      MuiList: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      MuiDrawer: {
        paper: {
          backgroundColor: "#1F2940",
        },
      },
      MuiPopover: {
        paper: {
          backgroundColor: "#3A455E",
        },
      },
      MuiTypography: {
        root: {
          color: "#FFFFFF",
        },
      },
      MuiFormControlLabel: {
        root: {
          marginRight: "0px",
        },
        labelPlacementBottom: {
          marginLeft: "0px",
        },
        label: {
          fontSize: "9px",
          color: "#707070",
        },
      },
      MuiTooltip: {
        popper: {
          left: "-53px !important",
        },
        tooltip: {
          padding: "0px 0px 0px 0px",
        },
      },
      MuiDivider: {
        vertical: {
          height: "37px",
        },
        root: {
          margin: "revert",
        },
      },
      MuiListItem: {
        button: {
          "&:hover": {
            backgroundColor: "unset",
          },
        },
      },
      MuiTab: {
        root: {
          "@media(min-width:1024px)": {
            minWidth: "auto",
          },
          "@media(min-width:600px)": {
            minWidth: "auto",
          },
          "@media (min-width: 0px)": {
            minWidth: "auto",
          },
          "&$selected": {
            color: "#1689FA !important",
          },
        },
      },
      MuiTabs: {
        indicator: {
          borderBottom: "4px solid #1689FA",
        },
      },
      MuiButton: {
        text: {
          padding: "10px 8px 6px 8px",
        },
        root: {
          "&:hover": {
            borderBottom: "3px solid #9DA7BF",
            borderRadius: 0,
          },
          "&:focus": {
            borderBottom: "3px solid #9DA7BF",
            borderRadius: 0,
          },
        },
      },
      MuiSwitch: {
        root: {
          marginTop: "-19px"
        },
        colorSecondary: {
          color: '#1E88E5',
          '&$checked': {
            color: '#1E88E5',
          },
        },
        track: {
          "$checked$checked + &": {
            backgroundColor: "#1E88E5"
          }
        }
      }
    },
    LeftMenu: {
      backgroundColor: "#3A455E",
    },
    DivderLeft: {
      background: "#9DA7BF",
    },
  });
  darkTheme = responsiveFontSizes(darkTheme);

  return (
    <Suspense fallback="Loading...">
      {/* {isLoading && ( */}
      <>
        {comCode.length > 5}
        {
          location.pathname === "/" ||
            location.pathname === "/page/404" ||
            location.pathname === `/${comCode[3]}` ||
            location.pathname === `/${comCode[3]}/` ||
            location.pathname === `/${comCode[3]}/rpi/setupdashboard` ||
            location.pathname === `/${comCode[3]}/rpi/launchdashboard` ||
            location.pathname === `/${comCode[3]}/rpi` ? (
            <AppRoutes />
          ) : (
            <div className={classes.root}>
              <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
                <CssBaseline>
                  {/* MAIN APPBAR */}
                  <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                      [classes.appBarShift]: open,
                    })}
                  >
                    <Toolbar>
                      <Typography href={reloadPage}>
                        <img
                          src={companyLogo || DefaultImage}
                          alt={companyName}
                          className={classes.avatar}
                        />
                      </Typography>
                      <h1 className={classes.CompanyLogo}>{companyName}</h1>
                      <div className={classes.grow} />
                      <FeedBackButton />
                      <LanguageSwitcher
                        darkMode={darkMode}
                        setDarkMode={setDarkMode}
                      />

                      <Tooltip title="" aria-label="Account">
                        <Button
                          aria-label="Account"
                          style={{ padding: "11px 8px 6px 8px" }}
                        >
                          <Account name={name} avatar={avatar} />
                        </Button>
                      </Tooltip>

                      <div style={{ paddingRight: "10px" }}>
                        <Tooltip title="" aria-label="RightSideMenu">
                          <IconButton
                            aria-label="RightSideMenu"
                            color="inherit"
                            edge="end"
                            onClick={() => setOpen(true)}
                            className={clsx(open && classes.hide)}
                          >
                            <img src={RightSideMenu} alt="RightSideMenu" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Toolbar>
                  </AppBar>

                  {/* LEFT DRAWER */}
                  <Drawer
                    variant="permanent"
                    className={clsx(classes.drawerOpen, classes.drawerClose)}
                    classes={{
                      paper: classes.drawerClose,
                    }}
                  >
                    <div className={classes.drawerHeader}>
                      <ListItem
                        style={{
                          backgroundColor: "#284F6C",
                          height: "40px",
                          paddingLeft: "10px",
                          marginBottom: "25px",
                        }}
                        // href={reloadPage}
                        onClick={handleLanding}
                      >
                        <img
                          src={Profile}
                          alt="LtwLogo"
                          style={{ width: "30px", height: "30px" }}

                        />
                      </ListItem>
                    </div>
                    <LeftMenu />
                    {/* <AppMenu /> */}
                  </Drawer>

                  <main
                    className={clsx(classes.content, {
                      [classes.contentShift]: open,
                    })}
                  >
                    <div className={classes.toolbar} />
                    <AppRoutes />
                  </main>

                  {/* RIGHT DRAWER */}
                  <Drawer
                    className={classes.drawer}
                    variant="temporary"
                    anchor="right"
                    open={open}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    <div onClick={() => setOpen(false)}>
                      <RightMenu />
                    </div>
                  </Drawer>
                </CssBaseline>
              </ThemeProvider>
            </div>
          )}
      </>
      {/* )} */}
    </Suspense>
  );
}

const Header = () => {
  return (
    <SnackbarProvider autoHideDuration={3000} maxSnack={2}>
      <Page />
    </SnackbarProvider>
  );
};

export default Header;
