import React, { useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";
import axios from "axios";
import {
  ListItemIcon,
  ListItem,
  List,
  FormControlLabel,
  Button,
  IconButton,
} from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";
import { BASE_API_URL } from "../../configurations/constant";
import { headers } from "../../services/CompanyProfileService";

const RightSideMenu = require("../../assets/images/appdrawer_white.svg");
const DefaultImage = require("../../assets/images/default_image_ltlabs_logo.svg");

const useStyles = makeStyles(() => ({
  imageIcon: {
    textAlign: "center",
    width: "30px"
  },
  divHover: {
    textAlign: "center",
    padding: "9px",
    textTransform: "inherit",
    "&:hover": {
      background: "#EFEFEF",
    },
  },
}));

function Page() {
  const classes = useStyles();
  const [isloading, setIsloading] = useState(false);
  const [getDatas, setGetDatats] = useState([]);
  const comCode = localStorage.getItem("companyCode");
  const userID = localStorage.getItem("ID");
  useEffect(() => {
    getDataRightMenu();
    // console.log("--document.readyState888-",document.readyState);
    setIsloading(true);
  }, []);

  const getDataRightMenu = async () => {
    // profile company code
    const headers = {
      Authorization: localStorage.getItem("token"),
      ContentType: "application/json",
    };
    await axios
      // .get(`${BASE_API_URL}productModule`, { headers })
      .get(`${BASE_API_URL}app-drawer?app=admin`, { headers })
      .then((res) => {
        setGetDatats(res.data.data.products);
        setIsloading(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleMenu = (event: any) => {
    for (let select of getDatas) {
      let pro = ((select["product"] !== "") && select["product"]);
      if(pro == event.target.alt){
        {select["product_url"] &&  window.open(`${select["product_url"]}`, "_blank")}
      }
    }

  };

  return (
    <div>
      <div style={{ backgroundColor: "rgb(50,95,128)", textAlign: "center" }}>
        <IconButton style={{ padding: "9px" }}>
          <img
            src={RightSideMenu}
            alt="RightSideMenu"
            style={{ width: "30px", height: "30px" }}
          />
        </IconButton>
      </div>
      {isloading && getDatas.length > 0 ? (getDatas.map((data:any, index:any) => (
          <div className={classes.divHover} key={index} 
          onClick={handleMenu}>
            <FormControlLabel
              value="bottom"
              control={
                <img
                  src={data["iconpath"] ? data["iconpath"] : DefaultImage}
                  alt={data["product"]}
                  className={classes.imageIcon}
                />
              }
              label={data["productDesc"]}
              labelPlacement="bottom"
              style={{ fontSize: 10 }}
            />
          </div>
        ))
      ) : (
        <div className={classes.divHover}>
          <FormControlLabel
            value="bottom"
            control={
              <img src={DefaultImage} alt="-" className={classes.imageIcon} />
            }
            label="-"
            labelPlacement="bottom"
            style={{ fontSize: 10 }}
          />
        </div>
      )}
    </div>
  );
}

const RightMenu = () => {
  return (
    <SnackbarProvider autoHideDuration={3000} maxSnack={2}>
      <Page />
    </SnackbarProvider>
  );
};

export default RightMenu;
