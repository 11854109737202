import { locale } from "../services/locale";
const ConpanyPrfile = require("../assets/images/ConpamyProfile_color.svg");
const FactorySetup = require("../assets/images/FactorySetup_color.svg");
const UserManagement = require("../assets/images/UserManagement_color.svg");
const ProductSettings = require("../assets/images/ProductSettings_color.svg");
const Translations = require("../assets/images/Translations_color.svg");
const ConpanyPrfileDarkMode = require("../assets/images/company_profile_darkmode.svg");
const FactorySetupDarkMode = require("../assets/images/factory_setup_darkmode.svg");
const ProductSettingDarkModes = require("../assets/images/product_settings_darkmode.svg");
const TranslationsDarkMode = require("../assets/images/translations_darkmode.svg");
const UserManagementDarkMode = require("../assets/images/user_management_darkmode.svg");
const CompanyPrfileMenu = require("../assets/images/ConpamyProfile_white.svg");
const FactorySetupMenu = require("../assets/images/FactorySetup_white.svg");
const UserManagementMenu = require("../assets/images/UserManagement_white.svg");
const ProductSettingsMenu = require("../assets/images/ProductSettings_white.svg");
const TranslationsMenu = require("../assets/images/Translations_white.svg");

const darkMode = localStorage.getItem("darkMode");

export const pages = {
    company: 'Company',
    factories: 'Factories',
    users: 'Users',
    settings: 'Settings',
    translations: 'Translations',
}

export const productModules = [
    {
        title: locale("Company Profile"),
        subTitle: locale("Keep your company information up to date"),
        icon: darkMode ? ConpanyPrfileDarkMode : ConpanyPrfile,
        path: 'companyprofile',
        order: 1,
        page: pages.company,
        enableAccess: false,
    },
    {
        title: locale("Factory Setup"),
        subTitle: locale("Setup and manage your factories, buildings, areas and lines"),
        icon: darkMode ? FactorySetupDarkMode : FactorySetup,
        path: 'factorysetup',
        order: 2,
        page: pages.factories,
        enableAccess: false,
    },
    {
        title: locale("User Management"),
        subTitle: locale("Manager users, access rights and role permissions"),
        icon: darkMode ? UserManagementDarkMode : UserManagement,
        path: 'usermgt',
        order: 3,
        page: pages.users,
        enableAccess: false,
    },
    {
        title: locale("Product Settings"),
        subTitle: locale("Manage Apps & their settings"),
        icon: darkMode ? ProductSettingDarkModes : ProductSettings,
        path: 'productsettings',
        order: 4,
        page: pages.settings,
        enableAccess: false,
    },
    {
        title: locale("Translations"),
        subTitle: locale("Manage language & translation settings"),
        icon: darkMode ? TranslationsDarkMode : Translations,
        path: 'translations',
        order: 5,
        page: pages.translations,
        enableAccess: false,
    },
];

export const imagesMenu = [
    { page: 'Company', icon: CompanyPrfileMenu },
    { page: 'Factories', icon: FactorySetupMenu },
    { page: 'Users', icon: UserManagementMenu },
    { page: 'Settings', icon: ProductSettingsMenu },
    { page: 'Translations', icon: TranslationsMenu },
];