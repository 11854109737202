/* HELPER FUNCTIONS */
import moment from 'moment'
import decoder from 'jwt-decode';

let superAdminFactoryId = 1;
export const getUserToken = () => {
    let userToken = JSON.parse(localStorage.getItem('token'));
    return userToken;
}
export const checkIfSuperAdmin = (id) => {
    if (id.toString() === superAdminFactoryId){
        return true;
    }
    else{
        return false;
    }
}

export const formatDate = (date, format = 'YYYY-MM-DD') => {
    return date ? moment(date).format(format) : '-';
};

export const isSuperAdmin = () => {
    let factoryId = getFactoryId();
    if (factoryId !== null){
        let flag = parseInt(factoryId) === superAdminFactoryId ? true : false;
        return flag
    }
    else{
        return false;
    }
}
export const hasChangePasswordPermission = (UserId) =>{
    const currentUserId = localStorage.getItem('ID');
    
    if(isSuperAdmin()) return true;
    else if( parseInt(currentUserId) === parseInt(UserId) ) return true;
    else return false;
}
export const checkPageActionAccess = (access, params) => {
    // if (access === undefined || access === null) {
    //     return false
    // }

    // let check = access.filter((item) => { return item.pageId === params.pageId && item.Action === params.pageAction && (item.isActive === "1" || item.isActive === 1) });
    // if (check.length > 0) {
    //     return true;
    // }
    // else {
    //     return false;
    // }
    return true;
}
export const checkPageAccessField = (access, params) => {
    if (access === undefined || access === null) {
        return false
    }

    let check = access.filter((item) => { return item.pageId === params.pageId && item.pageAccess === params.pageAccess });
    if (check.length > 0) {
        return true;
    }
    else {
        return false;
    }
}

export const capitalize = (word) => {
    alert("word:"+ word)
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export const getFactoryId = () => {
    let userToken = JSON.parse(localStorage.getItem('factoryId'));
    // if (userToken){
    //     let decodedToken = decoder(userToken);
    //     return decodedToken.factoryId;
    // }
    // else{
    //     return null;
    // }
    return userToken;
}

export const getUserId = async () => {
    let userToken = JSON.parse(localStorage['token'] ?? null)
    try {
        let {userId} = await decoder(userToken)
        return userId
    } catch (error) {
        console.error('getUserId', error)
    }
}

export const checkIfSuperAdminFactoryExist = (factoriesAssigned) => {
    if (factoriesAssigned){
        let check = factoriesAssigned.filter((item) => { return item.factoryId.toString() === superAdminFactoryId  });
        if (check.length > 0){
            return true;
        }
        else{
            return false;
        }
    }
    else{
        return false
    }
}





// export const getRole = () => {
//     let userRole = JSON.parse(localStorage.getItem('userRole'));
//     if (userRole){
//         return userRole;
//     }
//     else{
//         return null;
//     }
// }

// export const getUserIdDefault = () => {
//     let userToken = JSON.parse(localStorage.getItem('token'));
//     if (userToken){
//         let decodedToken = decoder(userToken);
//         return decodedToken.userId;
//     }
//     else{
//         return null;
//     }
// }





// export const checkIfSuperAdminFactoryExistInRoute = () => {
//     if (localStorage.getItem('factoriesAssigned')){
//         let factoriesAssigned = JSON.parse(localStorage.getItem('factoriesAssigned'));
//         let check = factoriesAssigned.filter((item) => { return item.factoryId.toString() === superAdminFactoryId  });
//         if (check.length > 0){
//             return true;
//         }
//         else{
//             return false;
//         }
//     }
//     else{
//         return false
//     }
// }

// export const checkIfSuperAdmin = (id) => {
//     if (id.toString() === superAdminFactoryId){
//         return true;
//     }
//     else{
//         return false;
//     }
// }





// export const checkIfApiLoading = (loadingArr) => {
//     let result = false;
//     loadingArr.map((loading) => {
//         if (loading) {
//             result = true;
//         }
//         return '';
//     });
//     return result;
// }




// export const checkRoleAccess = (access, params) => {
//     if (access === undefined || access === null) {
//         return false
//     }

//     let check = access.filter((item) => { return item.role === params.role && (item.isActive === "1" || item.isActive === 'N/A') });
//     if (check.length > 0) {
//         return true;
//     }
//     else {
//         return false;
//     }
// }

// export const checkPageAccess = (access, params) => {
//     if (access === undefined || access === null) {
//         return false
//     }

//     let check = access.filter((item) => { return item.pageId === params.pageId && (item.isActive === "1" || item.isActive === 'N/A') });
//     if (check.length > 0) {
//         return true;
//     }
//     else {
//         return false;
//     }
// }





// export const setters = () => {
//     /**
//      * Date setters for weekends 
//      * returns the 0 and 6 index of a week by the current date
//      */
//     Date.prototype.weekends = function () {
//         const formatDate = (arg) => {
//             return moment(arg).format('DD-MM-YYYY')
//         }

//         let arg = this
//         let weekday = arg.getDay()
//         let sunday = formatDate(new Date(arg.setDate(arg.getDate() - weekday)))
//         let saturday = formatDate(new Date(arg.setDate(arg.getDate() + 6)))

//         return { sunday, saturday }
//     }
//     /**setters for fullscreen */
//     if (!Element.prototype.requestFullscreen) Element.prototype.requestFullscreen = Element.prototype.mozRequestFullscreen || Element.prototype.webkitRequestFullscreen || Element.prototype.msRequestFullScreen
//     if (!document.exitFullscreen) document.exitFullscreen = document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen
//     if (!document.fullscreenElement) {
//         Object.defineProperty(document, 'fullscreenElement', {
//             get: function () {
//                 return document.mozFullScreenElement || document.msFullscreenElement || document.webkitFullscreenElement
//             }
//         })
//     }
//     if (!document.fullscreenEnabled) {
//         Object.defineProperty(document, 'fullscreenEnabled', {
//             get: function () {
//                 return document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitFullscreenEnabled
//             }
//         })
//     }
//     /**setters for translations */
//     /**stx_en */
//     String.prototype.translate = function () {
//         // console.log('translat', this)
//         // return this
//         let language = localStorage['selectedLanguage'] || 'en'
//         if (language != 'en') {
//             let concat
//             let isToSanitize = this.match(/^#/gi) ? true : false

//             if(isToSanitize) concat = '# '
            
//             let factory = localStorage['selectedFactory'] || 'stx'

//             let source = `${factory}_${language}`

//             let index = localStorage[source] && Object.entries(JSON.parse(localStorage[source])).findIndex(x => x[0].replace(/\s*/gi, '').toLowerCase() == this.replace(/^#|\s*/gi, '').toLowerCase())

//             let translations = localStorage[source] && Object.entries(JSON.parse(localStorage[source]))
//             return index == -1 ? this : translations && `${concat ?? ''}${translations[index][1]}` || this
//         }
//         else return this
//     }
// }
// /** transform languages to localStorage */
// export const languageToStorage = (languages) => {
//     if (Array.isArray(languages)) {
//         languages.forEach(x => {
//             localStorage[x.code] = JSON.stringify(x.data)
//         })
//     }
//     else localStorage[languages.code] = JSON.stringify(languages.data)
// }