import axios from "axios";
import { BASE_API_URL } from "../configurations/constant";

export async function auth(params: any, para: any) {
  try {
    // fire off requests and save their promises
    const companyData = axios.post(`${BASE_API_URL}companylogin`, params, para);

    // await all promises to come back and destructure the result into their own variables
    const [company] = await Promise.all([companyData]);
    return [company];
  } catch (error) {
    return error.response; //
  }
}

export async function formSetupdashboard(params: any) {
  try {
    // fire off requests and save their promises
    const companyData = axios.post(`${BASE_API_URL}rpi/dashboard`, params);

    // await all promises to come back and destructure the result into their own variables
    const [company] = await Promise.all([companyData]);
    return [company];
  } catch (error) {
    return error.response; //
  }
}

export async function formLogin(params: any, para: any) {
  try {
    // fire off requests and save their promises
    const userData = axios.post(
      `${BASE_API_URL}company/userlogin`,
      params,
      para
    );

    // await all promises to come back and destructure the result into their own variables
    const [user] = await Promise.all([userData]);
    return [user];
  } catch (error) {
    return error.response; //
  }
}

export async function getList(comCode: any) {
  try {
    // fire off requests and save their promises
    const getDec = axios.get(`${BASE_API_URL}company/:comCode`, comCode);

    // await all promises to come back and destructure the result into their own variables
    const [company] = await Promise.all([getDec]);
    return [company];
  } catch (error) {
    return error.response; //
  }
}

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  // localStorage.removeItem("newURLRPI");
  // localStorage.removeItem("newURLRPIs");
};

// set the token and user from the session storage
export const setUserSession = (token: any, user: any) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
};

//Set Remember Me
export const setRememberMe = (
  userId: any,
  password: any,
  companyCode: any,
  isRememberMe: any
) => {
  localStorage.setItem("userId", userId);
  localStorage.setItem("password", password);
  localStorage.setItem("companyCode", companyCode);
  localStorage.setItem("isRememberMe", isRememberMe);
};

//Get User ID
export const getUserId = () => {
  return localStorage.getItem("userId");
};

//Get Password
export const getPassword = () => {
  return localStorage.getItem("password");
};

//Get isRememberMe
export const getIsRememberMe = () => {
  return localStorage.getItem("isRememberMe");
};

//Get Company Code
export const getCompanyCode = () => {
  return localStorage.getItem("companyCode");
};

//Remove Remember Me
export const removeRememberMe = () => {
  localStorage.removeItem("userId");
  localStorage.removeItem("password");
  localStorage.removeItem("isRememberMe");
};
