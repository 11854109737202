import {
    LOADING,
    ERROR,
    DETAIL_FACTORY,
    DETAIL_BUILDINGS,
    DETAIL_SHIFTS,
    UPDATE_BANNER,
    UPDATE_PROFILE,
    OPEN_DIALOG,
    IS_EDITTING,
    ON_CHANGE,
} from './../ActionTypes/factoryActionTypes';

const factoryReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case LOADING:           return {...state, isLoading: true}
        case DETAIL_BUILDINGS:  return {...state, buildings: payload}
        case DETAIL_SHIFTS:     return {...state, shifts: payload}
        case OPEN_DIALOG:       return {...state, openDialog: !state.openDialog}
        case ON_CHANGE:         return {...state, ...payload}
        case UPDATE_BANNER:     return {...state, bannerImgLink: payload}
        case UPDATE_PROFILE:    return {...state, profileImgLink: payload}
        case IS_EDITTING:       return {...state, isEditing: !state.isEditing, openDialog: !state.openDialog}
        case ERROR:             return {...state, isLoading: false, error: payload}
        case DETAIL_FACTORY:    return {
            ...state,
            ...payload,
            id: payload.id,
            factoryId: payload.id,
            company: payload.company,
            desc: payload.description,
            address: payload.addr1,
            timeZone: payload.timezoneId.toString(),
            timezoneName: payload.timezoneName,
            createdDt: payload.createdDt?.split(' ')[0] || '',
            updatedDt: payload.updatedDt?.split(' ')[0] || '',
            success: true,
            isLoading: false,
        }
        default: return state;
    }
};

const initialState = {
    id: '',
    factoryId: '',
    isEditing: false,
    name: '',
    code: '',
    company: '',
    desc: '',
    address: '',
    isActive: '',
    timeZone: '',
    country: '',
    createdBy: '',
    createdDt: '',
    updatedBy: '',
    updatedDt: '',
    bannerImgLink: '',
    profileImgLink: '',
    buildings: [],
    shifts: [],
    isLoading: false,
    openDialog: false,
    isEditing: false,
    isDelete: false,
    success: false,
    error: false,
};

export default factoryReducer;