import React, { Suspense, lazy } from "react";
import {Switch, Route, BrowserRouter} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import PrivateRoute from "./utils/PrivateRoute";
import AllLines from "./pages/AllLines/AllLines";
import ViewByLine from "./pages/ViewByLine/ViewByLine";
import Analytics from "./pages/Analytics/Analytics";
import LTMDashboard from "./pages/LTMDashboard/LTMDashboard";
import LineOverView from "./pages/LineOverView/LineOverView";
import Machines from "./pages/Machines/Machines";

const Login = lazy(() => import("./pages/Authentication/Login"));
const CompanyLogin = lazy(() => import("./pages/Authentication/CompanyLogin"));
const InventoryAuthorized = lazy(() => import("./pages/Authentication/InventoryAuthorized"))

const RPILogin = lazy(() => import("./pages/RPIAuthentication/RPILogin"));
const RPICompanyLogin = lazy(
  () => import("./pages/RPIAuthentication/RPICompanyLogin")
);
const SetupDashboard = lazy(
  () => import("./pages/RPISetupDashboard/SetupDashboard")
);
const LaunchDashboard = lazy(
  () => import("./pages/RPISetupDashboard/LaunchDashboard")
);
const Page404 = lazy(() => import("./pages/Respon/Page404"));

const LandingPage = lazy(() => import("./pages/LandingPage/LandingPage"));

// LANDING PAGE ROUTES
const CompanyProfile = lazy(
  () => import("./pages/CompanyProfile/CompanyProfile")
);
const FactorySettings = lazy(
  () => import("./pages/FactorySettings/FactorySettings")
);
const ProductSettings = lazy(
  () => import("./pages/ProductSettings/ProductSettings")
);
const Translation = lazy(() => import("./pages/Translation/Translation"));
const SystemTranslationEdit = lazy(() => import("./pages/Translation/SystemTranslation/SystemTranslationEdit"));

const UserManagement = lazy(
  () => import("./pages/UserManagement/UserManagement")
);

const UserProfile = lazy(() => import("./pages/UserProfile/UserProfile"));
const ManageFactory = lazy(
  () => import("./pages/FactorySettings/ManageFactory/ManageFactory")
);
const AddRole = lazy(() => import("./pages/UserManagement/AddRole"));
const EditRole = lazy(() => import("./pages/UserManagement/EditRole"));

// const AddUser = lazy(() => import("./pages/UserManagement/UserAccessProfile/UserAccessProfile"));
const AddUser = lazy(() => import("./pages/UserManagement/AddUser"));
const EditUser = lazy(() => import("./pages/UserManagement/EditUserOld"));

const MasterDataProduct = lazy(() => import("./pages/Translation/MasterDataTranslation/MasterDataProduct"));

const MasterDataFactoryProduct = lazy(() => import("./pages/Translation/MasterDataTranslation/MasterDataFactoryProduct"));
function Page() {

  return (
    <>
      <Suspense fallback="Loading...">
        <Switch>
          <Route path="/" component={CompanyLogin} exact />
          <Route path="/authorized" component={InventoryAuthorized} exact />
          <Route path="/rpi" component={RPICompanyLogin} exact />
          <Route path="/:companyCode" component={Login} exact />
          <Route path="/:companyCode/rpi" component={RPILogin} exact />
          <Route path="/404" component={Page404} exact />

          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/rpi/setupdashboard"
              component={SetupDashboard}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/rpi/launchdashboard"
              component={LaunchDashboard}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/landingpage"
              component={LandingPage}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/companyprofile"
              component={CompanyProfile}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/factorysetup"
              component={FactorySettings}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/productsettings"
              component={ProductSettings}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/translations"
              component={Translation}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/systemTranslationedit/:id"
              component={SystemTranslationEdit}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/translations/products"
              component={MasterDataProduct}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/usermgt"
              component={UserManagement}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/userprofile"
              component={UserProfile}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/manageFactory/:id"
              component={ManageFactory}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/addRole"
              component={AddRole}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/editRole/:id"
              component={EditRole}
              exact
          />
          {/* <PrivateRoute
            isLogIn={true}
            path="/:companyCode/admin/user"
            component={AddUser}
            exact
          />
          <PrivateRoute
            isLogIn={true}
            path="/:companyCode/admin/user/:id"
            component={AddUser}
            exact
          /> */}
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/addUser"
              component={AddUser}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/editUser/:id"
              component={EditUser}
              exact
          />
          {/* LT Quality */}
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/ltq/dashboards/alllines"
              component={AllLines}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/ltq/viewbyline"
              component={ViewByLine}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/ltq/dashboards/analytics"
              component={Analytics}
              exact
          />
          {/* LT Mechanics */}
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/ltm/dashboard"
              component={LTMDashboard}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/ltm/lineoverview"
              component={LineOverView}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/ltm/machines"
              component={Machines}
              exact
          />
          <PrivateRoute
              isLogIn={true}
              path="/:companyCode/admin/translations/masterdata"
              component={MasterDataFactoryProduct}
              exact
          />

        </Switch>
      </Suspense>
    </>
  );
}

const AppRoutes = () => {
  return (
    <SnackbarProvider autoHideDuration={3000} maxSnack={2}>
      <Page />
    </SnackbarProvider>
  );
};

export default AppRoutes;
