import React from 'react';
import './assets/css/App.scss';
import Header from './pages/Shared/Header';
import { useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import Store from './pages/UserManagement/Action/store';
import store from './Redux/Store';
import { 
  ContextProvider as LocaleContextProvider,
  Context as LocaleContext,
} from './services/locale';

function App() {
  let location = useLocation();
  return (
    <div>
      <Provider store={store}>
        <LocaleContextProvider>
          <LocaleContext.Consumer>
            {()=> <Header/>}
          </LocaleContext.Consumer>
        </LocaleContextProvider>
        {(location.pathname === '/' || location.pathname === '/NotFound' || location.pathname === '/login')}
      </Provider>
    </div>
  );
}

export default App;