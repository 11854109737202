/**
 * This modoule is to read and write
 * localized object into defined storage RAM or localStorage
 */

import { LOCALE_STORAGE, DEFAULT_LOCALE } from '../../configurations/locale';

// @note Used in testing 
// import content from './locale.json';

window.i18n = window.i18n || {};
const LOCALE_DATA = 'locale-data';
const LOCALE = 'language';
const { localStorage } = window;
 
/**
* Reads the localized content from storage
* @return object
*/
export function read() {
  const locale = LOCALE_STORAGE === 'RAM'
    ? window.i18n
    : JSON.parse(localStorage.getItem(LOCALE_DATA));

  return locale || {};
}
 
/**
* Writes the localized content into defined storaged
* @param {object} locale Localized content
* @todo: Please change before move to production
*/
 
// @todo Use in testing
/*
export function write(locale) {
  const lang = getLanguage();
  const tmp = (lang === 'en') ? {} : content;
  if (LOCALE_STORAGE === 'RAM') {
    window.i18n = tmp;
  } else {
    const localized = JSON.stringify(tmp);
    localStorage.setItem(LOCALE_DATA, localized);
  }
}
  */
 
// @todo Used in production
export function write(locale) {
  if (LOCALE_STORAGE === 'RAM') {
    window.i18n = locale;
  } else {
    const localized = JSON.stringify(locale);
    localStorage.setItem(LOCALE_DATA, localized);
  }
}
 
/**
* Gets the saved language from local,
* if it is not saved, uses the default set in .env
* @return string
*/
export function getLanguage() {
  return localStorage.getItem(LOCALE) || DEFAULT_LOCALE;
}

/**
* Set the selected langauge
* @param {string} language Language to be set (km, en, zh-cn)
*/
export function setLanguage(lang) {
  localStorage.setItem(LOCALE, lang);
}
 
/**
* Exclude all the localized English phrase
* except the msg.KEY_NAME. But other laguage will be untouched.
* @param (string) key The key name of locale
* @param (string) lang Language en|km|
* @rturn object
*/
function byPass(key, lang) {
  if (lang !== 'en') return false;
  return !!!key.match(/msg\../);
}
 
/** Clean the localized object, This will also
* delete the English word, except member with description
* which in the format of msg.KEY_NAME
* @param {object} locale
* @return object
*/
export function cleanLocale(locale) {
  const lang = getLanguage();
  return Object.keys(locale).reduce((carry, key) => {
    const cleanKey = key.trim();
    if (byPass(cleanKey, lang)) return carry;
      return {
        ...carry,
        [cleanKey.toLowerCase()]: locale[key],
      };
  }, {});
}
