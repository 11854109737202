import { BASE_API_URL } from '../../configurations/constant';
import ajax from '../../services/ajax';
import {
  ERROR,
  LOADING,
  SUCCESS,
} from './../ActionTypes/profileActionTypes';

export const loading = () => {
  return {
    type: LOADING,
  };
};

export const success = (data) => {
  return {
    type: SUCCESS,
    payload: data,
  };
};

export const errorMessage = (message) => {
  return {
    type: ERROR,
    payload: message,
  };
};

export const getData = () => {
  return function (dispatch) {
    dispatch(loading());
    ajax(`${BASE_API_URL}me`)
        .then((data) => dispatch(success(data)))
        .catch((error) => dispatch(errorMessage(error?.response?.data?.message)));
  };
};