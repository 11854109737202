export const SUCCESS            = 'SUCCESS';
export const LOADING            = 'LOADING';
export const ERROR              = 'ERROR';

export const ON_CHANGE          = 'ON_CHANGE';
export const OPEN_DIALOG        = "OPEN_DIALOG";
export const IS_EDITTING        = "IS_EDITTING";

export const GET_COUNTRIES      = "GET_COUNTRIES";
export const GET_TIMEZONES      = "GET_TIMEZONES";

export const UPDATE_PROFILE     = 'UPDATE_PROFILE';
export const UPDATE_BANNER      = 'UPDATE_BANNER';

export const DETAIL_FACTORY     = 'DETAIL_FACTORY';
export const DETAIL_BUILDINGS   = 'DETAIL_BUILDINGS';
export const DETAIL_SHIFTS      = 'DETAIL_SHIFTS';